import type { NavBarExtension } from '@pflegenavi/frontend/layout';
import { useFeatureFlag } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag, FeatureFlagStatus } from '@pflegenavi/shared/api';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';

import { SvgIconStyle } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';

import bankIcon from './assets/bankIcon.svg';
import { useGetPaymentProcessor } from '@pflegenavi/frontend/api-nursing-home';

export const useConfig: () => NavBarExtension = () => {
  const paymentProcessor = useGetPaymentProcessor();

  const accountFeatureFlag = useFeatureFlag(FeatureFlag.AccountingPayments);
  const newAccountFeatureFlagEnabled =
    useFeatureFlag(FeatureFlag.AccountingPaymentsNew) ===
    FeatureFlagStatus.Enabled;

  const serviceProviderPaymentsFeatureFlag = useFeatureFlag(
    FeatureFlag.ServiceProviderPayments
  );

  const coinListFeatureFlagEnabled =
    useFeatureFlag(FeatureFlag.CoinList) === FeatureFlagStatus.Enabled;

  const { t } = useTranslation();

  if (
    accountFeatureFlag === FeatureFlagStatus.Disabled &&
    !newAccountFeatureFlagEnabled
  ) {
    return undefined;
  }

  if (newAccountFeatureFlagEnabled) {
    return {
      item: {
        icon: (
          <SvgIconStyle
            src={bankIcon}
            sx={{
              width: 1,
              height: 1,
            }}
          />
        ),
        title: newAccountFeatureFlagEnabled
          ? t('accounting.page.title-new')
          : t('accounting.page.title'),
        path: nhAppMainPages.NURSING_HOME_ACCOUNTING_OVERVIEW_NEW,
        children: [
          ...(coinListFeatureFlagEnabled
            ? [
                {
                  title: t('accounting.nav.overview'),
                  path: nhAppMainPages.NURSING_HOME_ACCOUNTING_OVERVIEW_NEW,
                },
                {
                  title: t('accounting.nav.transaction-groups'),
                  path: nhAppMainPages.NURSING_HOME_ACCOUNTING_TRANSACTION_GROUPS,
                },
                {
                  title: t('accounting.nav.transactions'),
                  path: nhAppMainPages.NURSING_HOME_ACCOUNTING_TRANSACTIONS,
                },
              ]
            : []),
          {
            title: t('accounting.nav.payment-details'),
            path: nhAppMainPages.NURSING_HOME_ACCOUNTING_PAYMENTS,
          },
          {
            title: t('accounting.nav.payouts'),
            path: nhAppMainPages.NURSING_HOME_ACCOUNTING_PAYOUTS,
          },
          ...(paymentProcessor === 'mangopay'
            ? [
                {
                  title: t('accounting.nav.card-payments'),
                  path: nhAppMainPages.NURSING_HOME_ACCOUNTING_CARD_PAYMENTS,
                },
              ]
            : []),
          ...(paymentProcessor !== 'mangopay' &&
          serviceProviderPaymentsFeatureFlag === FeatureFlagStatus.Enabled
            ? [
                {
                  title: t('accounting.nav.service-provider-payments'),
                  path: nhAppMainPages.NURSING_HOME_ACCOUNTING_SERVICE_PROVIDER_PAYMENTS,
                },
              ]
            : []),
        ],
      },
    };
  }

  return {
    item: {
      icon: (
        <SvgIconStyle
          src={bankIcon}
          sx={{
            width: 1,
            height: 1,
          }}
        />
      ),
      title: t('accounting.page.title'),
      path: nhAppMainPages.NURSING_HOME_ACCOUNTING_OVERVIEW,
      children: [
        {
          title: t('accounting.nav.overview'),
          path: nhAppMainPages.NURSING_HOME_ACCOUNTING_OVERVIEW,
        },
        {
          title: t('accounting.nav.payouts'),
          path: nhAppMainPages.NURSING_HOME_ACCOUNTING_PAYOUTS,
        },
        {
          title: t('accounting.nav.payment-details'),
          path: nhAppMainPages.NURSING_HOME_ACCOUNTING_PAYMENTS,
        },
        ...(serviceProviderPaymentsFeatureFlag === FeatureFlagStatus.Enabled
          ? [
              {
                title: t('accounting.nav.service-provider-payments'),
                path: nhAppMainPages.NURSING_HOME_ACCOUNTING_SERVICE_PROVIDER_PAYMENTS,
              },
            ]
          : []),
      ],
    },
  };
};
